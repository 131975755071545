import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from '@/api/amplify'

import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Simplesense | Log In',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Simplesense | Incident Feed',
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authenticated = await loggedIn()
  if (to.meta.requiresAuth && !authenticated) {
    next({ name: 'Login' })
  } else {
    document.title = to.meta.title
    next()
  }
})

async function loggedIn() {
  try {
    window.Auth = Auth
    await Auth.currentAuthenticatedUser()
    return true
  } catch (err) {
    return false
  }
}

export default router
