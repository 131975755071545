import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '@/store/mods/auth'
import incidents from '@/store/mods/incidents'
import maps from '@/store/mods/maps'
import chime from '@/store/mods/chime'
import client from '@/store/mods/client'
import forward from '@/store/mods/forward'

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    incidents,
    maps,
    chime,
    client,
    forward,
  },
})
