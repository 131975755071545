<template>
  <div class="incident">
    <span :class="unitTypeStyles(cadIncident.agency.type, ['marker'])"></span>
    <span class="incident-content">
      <div class="incident-header">
        <h3>{{ cadIncident | computeTitle }}</h3>
        <h4 class="date">{{ cadIncident.timestamps.received | fmtTime }}</h4>
      </div>
      <div>
        <div>Call Type: {{ cadIncident.callType }}</div>
        <div>Details: {{ cadIncident.description }}</div>
      </div>
      <div class="units">
        <span style="font-weight: 1000">Units: </span>
        {{ cadIncident.units.join(', ') }}
      </div>
      <div>
        <div class="geofence">GeoFence: {{ geofence.name }}</div>
        <div class="address">{{ cadIncident.location.streetAddress }}</div>
        <div>
          <button @click="whichOn('rawOn')" class="dispatch-button">
            Raw Dispatch
          </button>
          <button @click="whichOn('mapOn')" class="dispatch-button">
            Map
          </button>
          <button @click="whichOn('streetOn')" class="dispatch-button">
            Street View
          </button>
        </div>
      </div>

      <pre
        v-if="rawOn"
        class="raw"
      ><code>{{ JSON.stringify(cadIncident, null, 2) }}</code></pre>
      <Map
        v-else-if="mapOn || streetOn"
        :iKey="`${$vnode.key}`"
        :mapOrStreet="mapOn ? 'map' : 'street'"
        :coords="[
          cadIncident.location.geocoordinates.longitude,
          cadIncident.location.geocoordinates.latitude,
        ]"
        :incident="cadIncident"
      />
    </span>
  </div>
</template>

<script>
import Map from '@/components/Map'
import { unitTypeStyles } from '@/assets/js/css'
import { fmtTime } from '@/assets/js/time'

function computeTitle({ agency, dataSource }) {
  return 'string' === typeof agency.name && agency.name.length > 3
    ? agency.name
    : dataSource.name
}

export default {
  name: 'Incident_1',

  components: {
    Map,
  },

  props: {
    cadIncident: Object,
    geofence: Object,
    group: Object,
  },

  data() {
    return {
      rawOn: false,
      mapOn: false,
      streetOn: false,
    }
  },

  methods: {
    unitTypeStyles,

    // setCallTypeText() {
    //   if (this.incident.alert_call_type) {
    //     return 'Call Type: ' + this.cadIncident.description
    //   }

    //   let code = this.incident.raw_alert.match(/code:(.+)/i)
    //   if (code) return 'Code: ' + code[1]

    //   let details = this.incident.raw_alert.match(/details:(.+)/i)
    //   if (details) return 'Details: ' + details[1].slice(0, 30)

    //   return 'Unknown Call Type'
    // },

    whichOn(which) {
      const ons = ['rawOn', 'mapOn', 'streetOn']

      ons.forEach(on => {
        if (which != on) {
          this[on] = false
        } else {
          this[on] = !this[on]
        }
      })
    },
  },

  filters: {
    computeTitle,
    fmtTime,
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
