<template>
  <div class="incident">
    <span :class="unitTypeStyles(cadIncident.agency.type, ['marker'])"></span>
    <span
      :class="extractedKeywords ? 'incident-content-flag' : 'incident-content'"
    >
      <div v-if="extractedKeywords" class="flag">
        Potential <span>COVID</span>
      </div>
      <div class="incident-header">
        <h3>{{ cadIncident.dataSource.name }}</h3>
        <h4 class="date">{{ cadIncident.timestamps.received | fmtTime }}</h4>
      </div>
      <div>
        <div>Call Type: {{ cadIncident.callType }}</div>
        <div>Details: {{ cadIncident.description }}</div>
      </div>
      <div class="units">
        <span style="font-weight: 1000">Units: </span>
        {{ cadIncident.units.join(', ') }}
      </div>

      <div class="flex-container">
        <div>
          <div class="address">{{ cadIncident.location.streetAddress }}</div>
          <div class="geofence" style="font-weight: 500">
            GeoFence: {{ geofence.name }}
          </div>
          <div>
            <button @click="whichOn('rawOn')" class="dispatch-button">
              Raw Dispatch
            </button>
            <button @click="whichOn('mapOn')" class="dispatch-button">
              Map
            </button>
            <button @click="whichOn('streetOn')" class="dispatch-button">
              Street View
            </button>
          </div>
        </div>

        <IncidentShareButtons
          :incident="cadIncident"
          :item="forwardItems[cadIncident.uuid]"
          class="flex-column-container"
        >
        </IncidentShareButtons>
      </div>
      <pre
        v-if="rawOn"
        class="raw"
      ><code>[Source] <b>{{ cadIncident.agency.name }}</b> @ {{cadIncident.timestamps.received | fmtTime}}</code></pre>
      <pre
        v-if="rawOn"
        class="raw"
      ><code>{{ JSON.stringify(cadIncident, null , 2) }}</code></pre>
      <pre v-if="rawOn" class="raw"><code>{{dispatchLog()}}</code></pre>
      <Map
        v-else-if="mapOn || streetOn"
        :iKey="`${$vnode.key}`"
        :mapOrStreet="mapOn ? 'map' : 'street'"
        :coords="[
          cadIncident.location.geocoordinates.longitude,
          cadIncident.location.geocoordinates.latitude,
        ]"
        :incident="cadIncident"
      />
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Map from '@/components/Map'
import IncidentShareButtons from '@/components/incident/IncidentShareButtons'
import { unitTypeStyles } from '@/assets/js/css'
// import { rgx } from '@/assets/js/keywords'
import { fmtTime } from '@/assets/js/time'

export default {
  name: 'Incident_3',

  components: {
    Map,
    IncidentShareButtons,
  },

  props: {
    cadIncident: Object,
    geofence: Object,
    group: Object,
  },

  computed: {
    ...mapState({
      forwardItems: state => state.forward.items,
    }),
    extractedKeywords() {
      // this "feature" was setup to fail
      // get out of here without breaking anything else

      return null

      // if (
      //   this.cadIncident.description
      //     .toLowerCase()
      //     .includes('negative for covid')
      // )
      //   return null

      // const recurse = (obj, acc = []) =>
      //   Object.values(obj).reduce((a, v) => {
      //     if (typeof v === 'object' && null !== v) {
      //       return recurse(v, a)
      //     }

      //     if (v.toLowerCase().includes('negative for covid')) return acc
      //     const m = v.match(rgx.covid)
      //     if (!m) return acc

      //     acc.push(...m)
      //     return acc
      //   }, [])

      // const matches = recurse([
      //   this.cadIncident.description,
      //   ...this.cadIncident.comments,
      // ])

      // return matches.length ? matches : null
    },
  },

  data() {
    return {
      rawOn: false,
      mapOn: false,
      streetOn: false,
    }
  },

  methods: {
    unitTypeStyles,

    whichOn(which) {
      const ons = ['rawOn', 'mapOn', 'streetOn']

      ons.forEach(on => {
        if (which != on) {
          this[on] = false
        } else {
          this[on] = !this[on]
        }
      })
    },

    dispatchLog() {
      if (this.cadIncident?.allowedShares) {
        let dispatchLogs = ''
        let sortedArray = this.cadIncident.allowedShares
          .slice()
          .sort(function(a, b) {
            return new Date(a.pushed) - new Date(b.pushed)
          })
        sortedArray.forEach(function(log) {
          if (log.shared != false) {
            dispatchLogs =
              dispatchLogs +
              '[Destination] ' +
              log.endpoint +
              ' @ ' +
              fmtTime(log.pushed, 'YYYY:MM:DD HH:mm') +
              ' \n' +
              '[Status] ' +
              log.message +
              '\n'
          }
        })
        return dispatchLogs
      }
    },
  },

  filters: {
    fmtTime,
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
