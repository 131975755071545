import { Loader } from '@googlemaps/js-api-loader'

export default {
  namespaced: true,

  state: {
    loader: new Loader({
      apiKey: 'AIzaSyBEaEyQUMErYldR2pUbO3vbAV-f-xE8Zuc',
      version: 'weekly',
    }),
  },
}
