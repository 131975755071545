<template>
  <div>
    <div class="instructions">
      <p class="instructions">
        To complete your account set up enter a new password below.
      </p>
    </div>

    <input v-model="passwordNew" type="password" placeholder="new password" />
    <input
      v-model="passwordNewAgain"
      type="password"
      placeholder="new password again"
    />
    <button
      @click="submitCompleteNewPassword"
      :disabled="disabled"
      :class="disabled ? 'disabled' : ''"
    >
      change password
    </button>

    <PwRules
      :passwordNew="passwordNew"
      :passwordNewAgain="passwordNewAgain"
      @brokenRules="breakRules"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import PwRules from '@/components/login/PwRules'

export default {
  components: { PwRules },
  data() {
    return {
      passwordNew: '',
      passwordNewAgain: '',
      brokenRules: [],
    }
  },

  computed: {
    disabled() {
      return (
        !this.passwordNew.length ||
        !this.passwordNewAgain.length ||
        !!this.brokenRules.length
      )
    },
  },

  methods: {
    ...mapActions({
      completeNewPassword: 'auth/completeNewPassword',
    }),

    async submitCompleteNewPassword() {
      if (this.brokenRules.length) {
        return
      }

      await this.completeNewPassword({
        passwordNew: this.passwordNew,
      })
    },

    breakRules(val) {
      this.brokenRules = val
    },
  },
}
</script>

<style scoped src="@/assets/css/login.css"></style>
