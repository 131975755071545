import ClientAuth from '@/api/auth'
import { WebSocketClient } from '@simplesenseio/web-socket-client'

const { VUE_APP_WS_URL } = process.env

let client = null
let reconnecting = false
let timer = null

export default {
  namespaced: true,

  state: () => ({
    connected: false,
    online: window.navigator.onLine,
    initialized: false,
  }),

  mutations: {
    SET_CONNECTED(state, connected) {
      state.connected = !!connected
    },
    SET_ONLINE(state, online) {
      state.online = !!online
    },
    SET_INITIALIZED(state, initialized) {
      state.initialized = !!initialized
    },
  },

  getters: {
    client: () => client,
  },

  actions: {
    async initialize({ dispatch, rootState }) {
      if (null !== client) return

      if (rootState?.auth?.username) {
        dispatch('_connectClient')
      } else {
        this.subscribe(({ type }, { auth }) => {
          if (type.startsWith('auth/SET_USER')) {
            if (auth.username && (null === client || client?.readyState > 1)) {
              dispatch('_connectClient')
            } else if (!auth.username && client?.connected) {
              client.disconnect()
            }
          }
        })
      }
    },

    async _connectClient({ commit, dispatch, rootState }) {
      if (null === client) {
        client = new WebSocketClient({
          auth: new ClientAuth({ dispatch, rootState }),
          WebSocket,
          wsUrl: VUE_APP_WS_URL,
        })

        commit('SET_CONNECTED', client.connected)

        client.on('ready-state-changed', readyState => {
          commit('SET_CONNECTED', client.connected)
          if (readyState < 2) {
            reconnecting = false
            clearInterval(timer)
          } else if (!!rootState.auth.username && !reconnecting) {
            reconnecting = true
            timer = setInterval(() => {
              client.connect()
            }, 5000)
          }
        })

        window.addEventListener('offline', () => {
          commit('SET_ONLINE', false)
          reconnecting = true
          client._websocket.close()
        })

        window.addEventListener('online', () => {
          commit('SET_ONLINE', true)
          reconnecting = false
          if (!client.connected) client.connect()
        })
      }

      await client.connect()

      commit('SET_INITIALIZED', true)
    },
  },
}
