<template>
  <div>
    <h4>Incident Sync</h4>
    <div>
      <button
        @click="shareAPINotification(b.system)"
        v-for="b in buttons"
        :key="b.name"
        v-bind:disabled="b.disabled"
        class="share-alert-buttons tooltip "
        :class="b.cssClass"
      >
        <span class="tooltiptext">{{ b.state }}</span>
        <b>{{ b.name }}</b>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

const STATES = ['Not Synced', 'Pending', 'Shared', 'Error']

const STATES_CSS = ['', 'pending-share', 'shared', 'share-error']

export default {
  name: 'Incident_share_buttons',

  data: () => ({
    shares: {
      webtak: 'ATAK',
      copers: 'COPERS',
      active911: 'Active911',
      ids5: 'IDS5',
    },
  }),

  props: {
    incident: Object,
    item: Object,
  },

  computed: {
    buttons() {
      if (!this.item) return []

      return Object.entries(this.item)
        .map(([key, value]) => {
          const name = this.shares[key]

          if (!name) return null

          return {
            name,
            system: key,
            disabled: value > 0,
            state: STATES[value] || STATES[3],
            cssClass: STATES_CSS[value] || STATES_CSS[0],
          }
        })
        .filter(b => null !== b)
    },
  },

  methods: {
    ...mapActions({
      forward: 'forward/forwardIncident',
    }),

    async shareAPINotification(system) {
      await this.forward({
        cadIncident: this.incident,
        system,
      })
    },
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
