<template>
  <div>
    <div v-if="pwResetSuccess" class="instructions">
      <p class="instructions">password successfully reset!</p>
      <p class="instructions">please enter new password to continue</p>
    </div>
    <p class="instructions">Log In</p>
    <input
      v-model="_username"
      type="text"
      autocomplete="username"
      placeholder="username"
    />
    <input
      v-model="password"
      type="password"
      placeholder="password"
      autocomplete="password"
    />
    <button @click="submitLogin" :disabled="disabled">
      login
    </button>
    <br />
    <button @click="submitResetSend" class="pw-change">
      Forgot Password?
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    username: String,
  },

  computed: {
    ...mapState({
      pwResetSuccess: state => state.auth.pwResetSuccess,
    }),

    _username: {
      get: function() {
        return this.username
      },
      set: function(val) {
        this.$emit('updateUsername', String(val).trim())
      },
    },
    disabled() {
      return !this.username.length || !this.password.length
    },
  },

  data() {
    return { password: '' }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      resetSend: 'auth/resetSend',
    }),

    ...mapMutations({
      setLoginState: 'auth/SET_LOGIN_STATE',
    }),

    async submitLogin() {
      await this.login({
        username: this.username,
        password: this.password,
      })
    },

    async submitResetSend() {
      const sent = await this.resetSend({ username: this.username })
      if (!sent) return

      this.setLoginState({ loginState: 'PwReset' })
    },
  },
}
</script>

<style scoped src="@/assets/css/login.css" />
