import Vue from 'vue'

// icons can be found @ https://materialdesignicons.com/

// context aware styling
// import 'vue-material-design-icons/styles.css'

// import each icon here, and .component it below
import VolOff from 'icons/VolumeOff'
import VolHigh from 'icons/VolumeHigh'
import DotsVertical from 'icons/DotsVertical'
import Power from 'icons/Power'
import LockReset from 'icons/LockReset'

// each icon must be imported above and instantiated here
// follow "i-icon-name" naming convention
//
// use icons in vue templates with `<i-icon-name />`

Vue.component('i-vol-off', VolOff)
Vue.component('i-vol-hi', VolHigh)
Vue.component('i-dots', DotsVertical)
Vue.component('i-power', Power)
Vue.component('i-lock-reset', LockReset)

// do not remove !!
// exported so that main.js can find this file and load at build time
export default {}
