<template>
  <div>
    <Header />
    <Incidents />
    <Help @open="showModal" />
    <HelpModal v-show="isModalVisible" @close="closeModal" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Help from '@/components/Help'
import HelpModal from '@/components/HelpModal'
import Incidents from '@/components/incident/Incidents'

export default {
  name: 'Home',
  components: {
    Header,
    Help,
    HelpModal,
    Incidents,
  },
  data() {
    return {
      isModalVisible: false,
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
