<template>
  <div :id="'map_' + iKey" class="map" />
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Map',

  props: {
    incident: Object,
    coords: Array,
    mapOrStreet: String,
    iKey: String,
  },

  computed: {
    ...mapState({
      loader: state => state.maps.loader,
    }),
  },

  data() {
    return {
      map: null,
      marker: null,
      info: null,
    }
  },

  methods: {
    async makeMap() {
      await this.loader.load()
      const mapDiv = document.getElementById(`map_${this.iKey}`)
      const [lng, lat] = this.coords

      if (this.mapOrStreet === 'map') {
        // map
        this.map = new window.google.maps.Map(mapDiv, {
          center: { lat, lng },
          zoom: 15,
        })

        // marker
        this.marker = new window.google.maps.Marker({
          position: { lat, lng },
          map: this.map,
          title: 'Incident',
        })

        // info window
        this.info = new window.google.maps.InfoWindow({
          content: `<p style="font-size: medium; color: #111">
          ${this.incident.location.streetAddress}
          </p>`,
        })
        this.marker.addListener('click', () => {
          this.info.open(this.map, this.marker)
        })
      } else {
        // street view
        new window.google.maps.StreetViewPanorama(mapDiv, {
          position: { lat, lng },
          pov: { heading: 0, pitch: 0 },
          zoom: 1,
        })
      }
    },
  },

  created() {
    this.makeMap()
  },

  watch: {
    mapOrStreet() {
      this.makeMap()
    },
  },
}
</script>

<style scoped>
.map {
  height: 500px;
  width: 100%;
  z-index: 0;
}
</style>
