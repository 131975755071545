import router from '@/router'
import { Auth } from '@/api/amplify'

const VIEW_MAP = [
  [],
  ['erie-insurance', 'travis-afb', 'little-rock-afb'],
  [],
  ['wright-patterson-afb'],
]

export default {
  namespaced: true,

  state: {
    groups: [],
    loginErr: null,
    loginState: 'PwLogin',
    pwResetSuccess: false,
    refreshToken: null,
    tempUser: null,
    token: null,
    username: '',
    viewId: 0,
  },

  actions: {
    async login({ state, commit }, { username, password }) {
      // reset error/success states
      state.loginErr = null
      state.pwResetSuccess = false

      // acquire tokens from cognito
      let tokens
      try {
        tokens = await Auth.signIn(username, password)
      } catch (err) {
        if (err.code == 'NotAuthorizedException') {
          state.loginErr = err
        } else {
          state.loginErr = { message: 'login error' }
        }
        return
      }

      if (tokens.challengeName == 'NEW_PASSWORD_REQUIRED') {
        commit('SET_LOGIN_STATE', { loginState: 'PwForceChange' })
        commit('SET_TEMP_USER', { tempUser: tokens })
        return
      }

      commit('SET_USER', tokens)

      // go to Home
      router.push({ name: 'Home' })
    },

    async refresh(arg) {
      const { dispatch, commit } = arg

      // legacy gen 4 cleanup
      if (localStorage.getItem('tokens')) {
        localStorage.removeItem('tokens')
      }

      try {
        await Auth.currentSession()
        commit('SET_USER', await Auth.currentAuthenticatedUser())
      } catch (err) {
        dispatch('logout', { pushRoute: false })
      }
    },

    async logout({ commit }, { pushRoute = true }) {
      try {
        await Auth.signOut()
      } catch (error) {
        console.error('error signing out: ', error)
      }

      // clear state
      commit('SET_USER')

      // clear localStorage
      localStorage.clear()

      // go to Login
      commit('SET_LOGIN_STATE', { loginState: 'PwLogin' })
      pushRoute && router.push({ name: 'Login' })
    },

    async completeNewPassword({ state, commit }, { passwordNew }) {
      let tokens
      try {
        tokens = await Auth.completeNewPassword(state.tempUser, passwordNew)
      } catch (err) {
        state.loginErr = { message: `password change err: ${err}` }
        return
      }

      commit('SET_USER', tokens)
      commit('SET_LOGIN_STATE', { loginState: 'PwLogin' })

      // go to Home
      router.push({ name: 'Home' })
    },

    async resetSend({ state }, { username }) {
      state.loginErr = null

      if (!username.length) {
        state.loginErr = {
          message: 'User must provide username to request password reset',
        }
        return false
      }

      try {
        await Auth.forgotPassword(username)
      } catch (err) {
        state.loginErr = { message: 'error sending verification email' }
        return false
      }

      return true
    },

    async reset({ state, commit }, { username, code, passwordNew }) {
      try {
        await Auth.forgotPasswordSubmit(username, code, passwordNew)
      } catch (err) {
        state.loginErr = { message: 'failed to reset password' }
        return
      }

      commit('SET_LOGIN_STATE', { loginState: 'PwLogin' })
      state.pwResetSuccess = true
    },
  },

  mutations: {
    SET_USER(state, { keyPrefix, signInUserSession, storage, username } = {}) {
      if (!username) {
        state.groups = []
        state.loginErr = null
        state.loginState = 'PwLogin'
        state.refreshToken = null
        state.pwResetSuccess = false
        state.tempUser = null
        state.token = null
        state.username = ''
        state.viewId = 0
        return
      }

      const prefix = `${keyPrefix}.${username}`

      state.groups =
        signInUserSession?.accessToken?.payload?.['cognito:groups'] || []
      state.refreshToken = storage[`${prefix}.refreshToken`]
      state.token = storage[`${prefix}.accessToken`]
      state.username = username

      state.viewId =
        VIEW_MAP.findIndex(list => {
          return list.some(group => state.groups.includes(group))
        }) || 1
    },

    SET_LOGIN_STATE(state, { loginState }) {
      state.loginState = loginState
    },

    SET_TEMP_USER(state, { tempUser }) {
      state.tempUser = tempUser
    },
  },
}
