<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      username: state => state.auth.username,
    }),
  },

  methods: {
    ...mapActions({
      refresh: 'auth/refresh',
      initializeClient: 'client/initialize',
      initializeIncidents: 'incidents/initialize',
      initializeForward: 'forward/initialize',
    }),
  },

  async created() {
    if (!this.username) {
      await this.refresh()
    }
    await this.initializeClient()
    await this.initializeIncidents()
    await this.initializeForward()
  },
}
</script>

<style>
@import './assets/css/app.css';
@import './assets/css/vars.css';
</style>
