<template>
  <div class="pw-rules">
    <h4>password rules:</h4>
    <p v-for="(rule, i) in passwordRules" :key="i" :class="validClass(rule)">
      {{ rule.desc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    passwordNew: String,
    passwordNewAgain: String,
  },

  computed: {
    brokenRules: function() {
      return this.passwordRules.reduce((arr, rule) => {
        if (!rule.fn(this.passwordNew, this.passwordNewAgain)) {
          arr.push(rule.desc)
          return arr
        }
        // bubble broken rules up to parent
        this.$emit('brokenRules', arr)

        return arr
      }, [])
    },
  },

  data() {
    return {
      passwordRules: [
        { desc: 'must have at least 8 characters', fn: pw => pw.length >= 8 },
        {
          desc: 'must include at least one lowercase letter',
          fn: pw => /[a-z]/.test(pw),
        },
        {
          desc: 'must include at least one uppercase letter',
          fn: pw => /[A-Z]/.test(pw),
        },
        {
          desc: 'must include at least one number',
          fn: pw => /[0-9]/.test(pw),
        },
        {
          desc: 'must include at least 1 special char, one of _ ! @ # $ % &',
          fn: pw => /[_!@#$%^&]/.test(pw),
        },
        {
          desc: 'new passwords must match',
          fn: (pw, again) => again.length && pw == again,
        },
      ],
    }
  },

  methods: {
    validClass(rule) {
      if (this.brokenRules.includes(rule.desc)) {
        return 'invalid'
      }
      return 'valid'
    },
  },
}
</script>

<style scoped>
div.pw-rules {
  width: 40%;
  margin: 0 auto;
  text-align: left;
  font-size: 0.6em;
}

.valid {
  color: #afa;
}

.invalid {
  color: #d46;
}
</style>
