<template>
  <div>
    <div class="instructions">
      <p class="instructions">
        To ensure the security of your account we require a secondary
        verification measure to change your password. We have sent a
        verification code to the email attached to this account. Enter that code
        in the space indicated below to complete the password change request.
      </p>
    </div>

    <input v-model="_username" type="text" placeholder="username" />
    <input v-model="passwordNew" type="password" placeholder="new password" />
    <input
      v-model="passwordNewAgain"
      type="password"
      placeholder="new password again"
    />
    <input
      v-model="verificationCode"
      type="text"
      placeholder="verification code"
    />
    <button @click="submitReset" :disabled="disabled">
      reset password
    </button>
    <br />
    <button @click="setLoginState({ loginState: 'PwLogin' })" class="pw-change">
      back to login
    </button>

    <PwRules
      :passwordNew="passwordNew"
      :passwordNewAgain="passwordNewAgain"
      @brokenRules="breakRules"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import PwRules from '@/components/login/PwRules'

export default {
  components: {
    PwRules,
  },

  props: {
    username: String,
  },

  computed: {
    _username: {
      get: function() {
        return this.username
      },
      set: function(val) {
        this.$emit('updateUsername', String(val).trim())
      },
    },

    disabled() {
      return (
        !this.username.length ||
        !this.passwordNew.length ||
        !this.passwordNewAgain.length ||
        !this.verificationCode ||
        !!this.brokenRules.length
      )
    },
  },

  data() {
    return {
      verificationCode: '',
      passwordNew: '',
      passwordNewAgain: '',
      brokenRules: [],
    }
  },

  methods: {
    ...mapActions({ reset: 'auth/reset', checkRules: 'auth/checkRules' }),

    ...mapMutations({
      setLoginState: 'auth/SET_LOGIN_STATE',
    }),

    async submitReset() {
      await this.reset({
        username: this.username,
        code: this.verificationCode,
        passwordNew: this.passwordNew,
      })
    },

    breakRules(val) {
      this.brokenRules = val
    },
  },
}
</script>
<style scoped src="@/assets/css/login.css"></style>
