<template>
  <div>
    <Loading v-if="loading" />
    <div v-else class="incident-container">
      <div v-if="!!message.length">
        <h3 class="message">
          {{ message }}
        </h3>
        <br />
        <h5 v-if="incidentErr">
          For assistance contact us at
          <a href="mailto:help@simplesense.io">help@simplesense.io</a>
        </h5>
      </div>
      <component
        :is="`Incident_${viewId}`"
        v-for="item in items"
        :key="item.cadIncident.id"
        :cadIncident="item.cadIncident"
        :geofence="item.geofence"
        :group="item.group"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Incident_0 from '@/components/incident/Incident_0'
import Incident_1 from '@/components/incident/Incident_1'
import Incident_3 from '@/components/incident/Incident_3'
import Loading from '@/components/Loading'
import CreateIncidentModal from '@/components/incident/CreateIncidentModal'

export default {
  name: 'Incidents',

  components: {
    Incident_0,
    Incident_1,
    Incident_3,
    Loading,
    CreateIncidentModal,
  },

  computed: {
    ...mapState({
      items: state => state.incidents.items,
      viewId: state => state.auth.viewId,
      loading: state => state.incidents.loading,
    }),
    message() {
      if (0 === this.items.length && !this.loading) {
        return 'No Recent Incidents'
      }
      return ''
    },
  },
  data() {
    return {
      isModalVisible: false,
      incidents: [],
      incidentErr: false,
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
