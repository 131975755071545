import Amplify, { Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
})

export { Auth }
