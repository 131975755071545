<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <span :class="unitTypeStyles('fire', ['modal-marker'])"></span>
        <div class="container">
          <form @submit.prevent="save">
            <div class="row">
              <header class="modal-header" id="modalTitle">
                <slot name="header">
                  <div class="col-75">
                    {{ cadIncident.agency.name }}
                  </div>
                  <div class="col-25-right">
                    {{ returnDate() }}
                  </div>
                </slot>
              </header>
            </div>
            <section class="modal-body" id="modalDescription">
              <slot name="body">
                <div class="row">
                  <div class="col-25">
                    <label for="callType">Call Type: </label>
                  </div>
                  <div class="col-75">
                    <input
                      type="text"
                      name="callType"
                      v-model="callType"
                      placeholder="Call Type"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-25">
                    <label for="code">Code: </label>
                  </div>
                  <div class="col-75">
                    <input
                      type="text"
                      name="code"
                      placeholder="Code"
                      v-model="description"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-25">
                    <label for="location">Location: </label>
                  </div>
                  <div class="col-75">
                    <div id="locationField">
                      <div>
                        <input
                          type="text"
                          v-model="location"
                          list="searchResults"
                          required
                        />
                        <datalist id="searchResults">
                          <option
                            v-for="(location, i) in searchResults"
                            :key="i"
                            v-bind:value="location.description"
                          >
                            {{ location.description }}
                          </option>
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-25">
                    <label for="units">Units: </label>
                  </div>
                  <div class="col-75">
                    <input
                      type="text"
                      v-model="units"
                      name="units"
                      placeholder="Units"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-25">
                    <label for="info">Info: </label>
                  </div>
                  <div class="col-75">
                    <input
                      type="text"
                      name="info"
                      v-model="comment"
                      placeholder="Info"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-25">
                    <input
                      type="checkbox"
                      id="Active911"
                      name="Active911"
                      v-model="sharedWithActive911"
                    />
                    <label class="checkbox-label" for="Active911"
                      >Active911</label
                    >
                  </div>
                  <div class="col-25">
                    <input
                      type="checkbox"
                      id="Copers"
                      name="Copers"
                      v-model="sharedWithCopers"
                    />
                    <label class="checkbox-label" for="Copers">COPERS</label>
                  </div>
                  <div class="col-25">
                    <input
                      type="checkbox"
                      id="IDS5"
                      name="IDS5"
                      v-model="sharedWithIds5"
                    />
                    <label class="checkbox-label" for="IDS5">IDS5</label>
                  </div>
                  <div class="col-25">
                    <input
                      type="checkbox"
                      id="ATAK"
                      name="ATAK"
                      v-model="sharedWithAtak"
                    />
                    <label class="checkbox-label" for="ATAK">ATAK</label>
                  </div>
                </div>
              </slot>
            </section>
            <footer class="modal-footer">
              <slot name="footer">
                <button
                  class="btn-close"
                  type="button"
                  @click="close"
                  aria-label="Close modal"
                >
                  Cancel
                </button>
                <button
                  class="btn-green"
                  type="submit"
                  aria-label="Create Alert"
                >
                  Create Alert
                </button>
              </slot>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { unitTypeStyles } from '@/assets/js/css'
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import { fmtTime } from '@/assets/js/time'
import { cadIncident } from '@simplesenseio/json-schema/utils'
import Https from '@/api/http'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

function getDefaultIncident() {
  return {
    agency: {
      name: 'Wright-Patterson Fire Dispatch',
      type: 'Fire',
    },
    cadId: '',
    callType: '',
    comments: [],
    dataSource: {
      name: 'Wright-Patterson AFB FD',
      uuid: '1b4c7dc1-6d75-56d4-a693-c5ca6e040c92',
    },
    description: '',
    location: {
      context: '',
      crossStreets: [],
      geocoordinates: {
        latitude: 0,
        longitude: 0,
      },
      streetAddress: '',
    },
    priority: 'Unknown',
    timestamps: {
      received: null,
    },
    units: [],
    uuid: '',
  }
}

export default {
  name: 'CreateIncidentModal',
  data() {
    return {
      cadIncident: {},
      callType: '',
      comment: '',
      description: '',
      units: '',
      sharedWithActive911: false,
      sharedWithCopers: false,
      sharedWithAtak: false,
      sharedWithIds5: false,
      location: '',
      searchResults: [],
      service: null, // will reveal this later
    }
  },
  computed: {
    ...mapState({
      token: state => state.forward.token,
    }),
  },
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyBEaEyQUMErYldR2pUbO3vbAV-f-xE8Zuc&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.MapsInit(), // will declare it in methods
        },
      ],
    }
  },

  methods: {
    ...mapActions({
      addToForwardCache: 'forward/addToCache',
    }),
    unitTypeStyles,
    close() {
      this.$emit('close')
    },
    save() {
      this.createAlert()
      this.$emit('close')
    },
    reset() {
      this.cadIncident = getDefaultIncident()
      this.callType = ''
      this.comment = ''
      this.description = ''
      this.units = ''
      this.sharedWithActive911 = false
      this.sharedWithCopers = false
      this.sharedWithAtak = false
      this.sharedWithIds5 = false
    },
    returnDate() {
      return fmtTime(new Date(), 'HH:mm')
    },
    async createAlert() {
      const ts = Date.now()
      const timestamp = new Date(ts).toISOString()

      if (this.comment) {
        this.cadIncident.comments.push({
          comment: this.comment,
          timestamp,
        })
      }

      if (this.callType) {
        this.cadIncident.callType = this.callType
      }

      if (this.description) {
        this.cadIncident.description = this.description
      }

      if (this.units) {
        this.cadIncident.units = this.units
          .split(',')
          .map(str => String(str).trim())
      }

      this.cadIncident.cadId = `${ts}`
      this.cadIncident.timestamps.received = timestamp
      this.cadIncident.uuid = cadIncident.generateUuid(this.cadIncident)

      const request = new Https({ authorization: this.token })
      await request.post(
        '/v1/wright-patterson-afb/incident/cad/',
        this.cadIncident,
      )

      const systems = [
        this.sharedWithActive911 ? 'active911' : null,
        this.sharedWithCopers ? 'copers' : null,
        this.sharedWithAtak ? 'webtak' : null,
        this.sharedWithIds5 ? 'ids5' : null,
      ].filter(s => null !== s)

      if (systems.length) {
        await this.addToForwardCache({
          cadId: this.cadIncident.uuid,
          systems,
        })
      }

      this.reset()
    },

    setLocation() {
      let geocoder = new window.google.maps.Geocoder()

      geocoder.geocode({ address: this.location }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          this.cadIncident.location.geocoordinates.latitude = results[0]?.geometry?.location?.lat()
          this.cadIncident.location.geocoordinates.longitude = results[0]?.geometry?.location?.lng()
          this.cadIncident.location.streetAddress =
            results[0]?.formatted_address
          this.address = this.location
        }
      })
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService()
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      this.searchResults = predictions
    },
  },

  watch: {
    location(newValue) {
      if (newValue) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.service.getPlacePredictions(
            {
              input: this.location,
            },
            this.displaySuggestions,
          )
          this.setLocation()
        }, 300)
      }
    },
  },

  created() {
    this.reset()
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
