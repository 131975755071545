import { AuthCore } from '@simplesenseio/web-socket-client'

class Auth extends AuthCore {
  constructor({ dispatch, rootState }) {
    super()
    this._vuexDispatch = dispatch
    this._vuexAuthState = rootState.auth
  }

  get token() {
    return this._vuexAuthState.token
  }

  get refreshToken() {
    return this._vuexAuthState.refreshToken
  }

  async logout() {
    // do nothing
    // vuex handles the tokens
  }

  async refresh() {
    return this._vuexDispatch('auth/refresh', null, { root: true })
  }
}

export default Auth
