<template>
  <div class="header">
    <div class="header-container">
      <h5 class="title">Simplesense Incident Feed</h5>

      <span v-if="username.length" class="actions">
        <span v-if="viewId == 3">
          <button
            type="button"
            class="create-dispatch-button"
            @click="showModal"
          >
            Create Alert
          </button>
          <CreateIncidentModal v-show="isModalVisible" @close="closeModal" />
        </span>

        <button
          @click="reload"
          @mouseover="hoverReload = true"
          @mouseleave="hoverReload = false"
          class="reload"
        >
          {{ hoverReload ? 'Reload' : connectedState }}
        </button>
        <button @click="toggleBeep" class="sound">
          <i-vol-off v-if="isMuted" :size="18" class="vol muted-vol" /><i-vol-hi
            v-else
            :size="18"
            class="vol"
          />
        </button>
        <button class="dots" @click="menuOn = !menuOn">
          <i-dots :size="29" fillColor="#fefefe" class="icon" />
          <div v-if="menuOn" class="menu">
            <button @click="logout">
              <i-power class="menu-icons" />Logout
            </button>
            <button @click="submitResetSend">
              <i-lock-reset class="menu-icons" />Reset password
            </button>
          </div>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CreateIncidentModal from '@/components/incident/CreateIncidentModal'

export default {
  name: 'Header',

  props: {
    msg: String,
  },
  components: {
    CreateIncidentModal,
  },

  computed: {
    ...mapState({
      username: state => state.auth.username,
      loading: state => state.incidents.loading,
      online: state => state.client.online,
      connected: state => state.client.connected,
      isMuted: state => state.chime.isMuted,
      viewId: state => state.auth.viewId,
    }),
    connectedState() {
      if (false === this.online) {
        return 'Offline'
      } else if (false === this.connected) {
        return 'Not Connected'
      } else if (true === this.loading) {
        return 'Loading'
      } else {
        return 'Connected'
      }
    },
  },

  data() {
    return {
      hoverReload: false,
      menuOn: false,
      isModalVisible: false,
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      refresh: 'auth/refresh',
      resetSend: 'auth/resetSend',
    }),

    ...mapMutations({
      toggleBeep: 'chime/TOGGLE_IS_MUTED',
      setLoginState: 'auth/SET_LOGIN_STATE',
    }),

    reload() {
      location.reload()
    },

    async submitResetSend() {
      const sent = await this.resetSend({ username: this.username })
      if (!sent) return

      this.setLoginState({ loginState: 'PwReset' })

      this.$router.push({ name: 'Login' })
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>

<style scoped>
.header {
  position: sticky;
  top: 0;
  min-height: 60px;
  margin-bottom: 4%;
  background: #444;
  text-align: left;
  z-index: 1;
}

h5.title {
  position: relative;
  margin: 0;
  top: 15px;
  font-weight: normal;
}

.actions {
  position: relative;
  float: right;
  bottom: 15px;
  margin: 0;
}

button {
  height: 35px;
  background: none;
  font-size: medium;
  border: none;
}

.header-container {
  width: 76%;
  margin: auto auto auto auto;
}

.reload {
  position: relative;
  min-width: 111px;
  bottom: 5px;
  border: 1px var(--simplesense) solid;
  color: var(--simplesense);
  border-radius: 4px;
  vertical-align: middle;
}

.sound {
  position: relative;
  bottom: 5px;
  color: var(--simplesense);
  border: 1px var(--simplesense) solid;
  border-radius: 4px;
  vertical-align: middle;
}

.vol {
  position: relative;

  top: 6%;
}
.muted-vol {
  color: var(--font-color);
}

.dots {
  height: 37px;
  width: 37px;
  background: #55f;
  border: none;
  border-radius: 50%;
  margin-left: 25px;
  padding-left: 8px;
}

.icon {
  position: relative;
  top: 5%;
  right: 20%;
}

.menu {
  position: absolute;
  top: 40px;
  right: 0;
  width: 192px;
  padding: 3%;
  background: #ddd;
  border-radius: 4px;
  text-align: left;
}

.menu-icons {
  position: relative;
  top: 6px;
  right: 7px;
}

.create-dispatch-button {
  position: relative;
  min-width: 111px;
  bottom: 5px;
  background-color: #007bff;
  font-size: medium;
  color: var(--font-color);
  border-radius: 4px;
  vertical-align: middle;
  margin-right: 25px;
}
</style>
