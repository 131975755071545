import Vue from 'vue'
import { Crud } from '@simplesenseio/web-socket-client'

const MAX_ITEMS = 25

export default {
  namespaced: true,

  state: () => ({
    items: [],
    loading: true,
  }),

  mutations: {
    SET_ITEMS(state, items) {
      const list = state.items

      for (const item of items) {
        const index = list.findIndex(
          ({ cadIncident: { id } }) => id === item.cadIncident.id,
        )
        if (index < 0) {
          const sortIndex = list.findIndex(({ cadIncident }) => {
            const itemTs = new Date(item.cadIncident.timestamps.received)
            const currentTs = new Date(cadIncident.timestamps.received)

            return itemTs.getTime() > currentTs.getTime()
          })

          if (sortIndex < 0) {
            list.push(item)
          } else {
            list.splice(sortIndex, 0, item)
          }
        } else {
          Vue.set(list, index, item)
        }
      }

      while (list.length > MAX_ITEMS) {
        const index = list.length - 1
        Vue.delete(list, index)
      }
    },

    SET_LOADING(state, loading) {
      state.loading = !!loading
    },
  },

  actions: {
    async initialize({ dispatch, rootState }) {
      if (rootState?.client?.initialized) {
        dispatch('_addListeners')
      } else {
        const unsubscribe = this.subscribe(({ type }, { client }) => {
          if (type.startsWith('client/SET_INITIALIZED') && client.initialized) {
            dispatch('_addListeners')
            unsubscribe()
          }
        })
      }
    },

    async _addListeners({ commit, dispatch, rootGetters }) {
      const client = rootGetters['client/client']
      const crud = new Crud({
        name: 'cad-geofence-notification',
        client,
      })

      const items = (await crud.list()) || []
      if (items.length) commit('SET_ITEMS', items)
      commit('SET_LOADING', false)

      crud.on('created', arg => {
        dispatch('chime/beep', null, { root: true })
        commit('SET_ITEMS', arg)
      })
    },
  },
}
