export default {
  namespaced: true,

  state: {
    a: new (window.AudioContext || window.webkitAudioContext)(),
    isMuted: false,
  },

  actions: {
    beep({ state }) {
      if (state.isMuted) return

      const oscillator = state.a.createOscillator()
      const gain = state.a.createGain()
      oscillator.connect(gain)
      oscillator.frequency.value = 500
      oscillator.type = 'triangle'
      gain.connect(state.a.destination)
      gain.gain.value = 5
      oscillator.start(state.a.currentTime)
      oscillator.stop(state.a.currentTime + 200 * 0.001)
    },
  },

  mutations: {
    TOGGLE_IS_MUTED(state) {
      state.isMuted = !state.isMuted
    },
  },
}
