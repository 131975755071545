import { Crud } from '@simplesenseio/web-socket-client'

function defaultItem(id) {
  return {
    cadId: id,
    webtak: 0,
    copers: 0,
    active911: 0,
    ids5: 0,
  }
}

export default {
  namespaced: true,

  state: () => ({
    items: {},
    cache: {},
    token: null,
    _crud: null,
  }),

  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },

    SET_CACHE(state, cache) {
      state.cache = cache
    },

    SET_TOKEN(state, token) {
      state.token = token
    },
  },

  actions: {
    async initialize({ dispatch, rootState, state }) {
      if (
        !rootState?.auth?.groups.some(group => {
          return group.toLowerCase() === 'wright-patterson-afb'
        })
      ) {
        return
      }
      if (rootState?.client?.initialized) {
        dispatch('_addListeners')
      } else {
        this.subscribe(({ type }, { client, incidents }) => {
          if (
            type.startsWith('client/SET_INITIALIZED') &&
            client.initialized &&
            !state._crud
          ) {
            dispatch('_addListeners')
          } else if (
            type.startsWith('incidents/SET_ITEMS') &&
            state._crud &&
            incidents.items.length
          ) {
            dispatch('_syncItems')
          }
        })
      }
    },

    async _addListeners({ commit, dispatch, rootGetters, state }) {
      const client = rootGetters['client/client']

      state._crud = new Crud({
        name: 'wp-forward',
        client,
      })

      state._crud.on('created', items => {
        dispatch('_syncItems', items)
      })

      state._crud.on('updated', items => {
        dispatch('_syncItems', items)
      })

      const token = await state._crud._sendRequest({ action: 'getCreateAuth' })
      commit('SET_TOKEN', token)
    },

    async _syncItems({ commit, dispatch, state, rootState }, newItems = []) {
      const cache = { ...state.cache }
      for (const { cadId } of newItems) {
        delete cache[cadId]
      }
      commit('SET_CACHE', cache)

      const upsert = []
      const cadIncidents = rootState.incidents.items.map(({ cadIncident }) => {
        const cadId = cadIncident.uuid

        if (state.cache[cadId]) {
          upsert.push(
            ...state.cache[cadId].map(system => ({ cadIncident, system })),
          )
        }

        return cadId
      })
      const currentItems = state.items
      const items = {}
      const queryIds = []

      for (const cadId of cadIncidents) {
        const item =
          newItems.find(i => i.cadId === cadId) || currentItems[cadId]
        if (item) {
          items[cadId] = { ...defaultItem(cadId), ...item }
        } else {
          queryIds.push(cadId)
          items[cadId] = defaultItem(cadId)
        }
      }

      if (queryIds.length) {
        for (const item of await state._crud.list(queryIds)) {
          items[item.cadId] = { ...items[item.cadId], ...item }
        }
      }

      commit('SET_ITEMS', items)

      if (upsert.length) {
        dispatch('forwardIncident', upsert)
      }
    },

    async addToCache({ commit, state }, item) {
      const cache = { ...state.cache }
      cache[item.cadId] = item.systems
      commit('SET_CACHE', cache)
    },

    async forwardIncident({ state }, items = []) {
      if (!Array.isArray(items)) items = [items]

      Promise.all(
        items.map(item => {
          try {
            return state._crud.upsert(item)
          } catch (err) {
            console.error(err)
          }
        }),
      )
    },
  },
}
