function defaultParams(obj, authorization) {
  return {
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Authorization: `Basic ${authorization}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    ...obj,
  }
}
class Https {
  constructor({ hostname = process.env.VUE_APP_REST_API, authorization }) {
    this._authorization = authorization
    this._hostname = hostname.startsWith('https://')
      ? hostname
      : `https://${hostname}`
  }

  async _sendRequest(path = '/', method = 'GET', data) {
    const params = defaultParams({ method }, this._authorization)
    const url = `${this._hostname}${path}`

    if (undefined !== data) params.body = JSON.stringify(data)

    const response = await fetch(url, params)

    return response.json()
  }

  async get(path) {
    return this._sendRequest(path, 'GET')
  }

  async post(path, data) {
    return this._sendRequest(path, 'POST', data)
  }
}

export default Https
