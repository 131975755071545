<template>
  <div>
    <Header />
    <div v-if="loginErr" class="error">{{ loginErr.message }}</div>
    <component
      :is="loginState"
      :username="username"
      @updateUsername="updateUsername"
    />
    <div class="help">
      Need assistance? Contact us at
      <a mailto="help@simplesense.io">help@simplesense.io</a>
    </div>
  </div>
</template>

<script>
// TODO: replace email with real email address
import { mapState } from 'vuex'

import Header from '@/components/Header'
import PwLogin from '@/components/login/PwLogin'
import PwForceChange from '@/components/login/PwForceChange'
import PwReset from '@/components/login/PwReset'

export default {
  components: { Header, PwLogin, PwForceChange, PwReset },

  computed: {
    ...mapState({
      loginErr: state => state.auth.loginErr,
      loginState: state => state.auth.loginState,
    }),
  },

  data() {
    return {
      username: '',
    }
  },

  methods: {
    updateUsername(val) {
      this.username = val
    },
  },
}
</script>

<style scoped>
.error {
  margin-top: 10%;
  color: #e68;
  font-size: smaller;
}

.help {
  margin-top: 2%;
  font-size: 0.6em;
}
</style>
