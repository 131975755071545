import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import icons from '@/assets/icons/icon'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  icons,
  render: h => h(App),
}).$mount('#app')
