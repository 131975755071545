<template>
  <div class="incident">
    <span :class="unitTypeStyles(cadIncident.agency.type, ['marker'])"></span>

    <span class="incident-content">
      <div class="incident-header">
        <h3>{{ cadIncident.dataSource.name }}</h3>
        <h4 class="date">{{ cadIncident.timestamps.received | fmtTime }}</h4>
      </div>

      <div class="units">
        <span style="font-weight: 1000">Units: </span>
        {{ cadIncident.units.join(',') }}
      </div>

      <div class="geofence">GeoFence: {{ geofence.name }}</div>
      <div class="address">{{ cadIncident.location.streetAddress }}</div>

      <div class="dispatch-actions">
        <button @click="whichOn('rawOn')" class="dispatch-button">
          Raw Dispatch
        </button>
      </div>

      <pre
        v-if="rawOn"
        class="raw"
      ><code>{{ JSON.stringify(cadIncident, null, 2) }}</code></pre>
    </span>
  </div>
</template>

<script>
import { unitTypeStyles } from '@/assets/js/css'
import { fmtTime } from '@/assets/js/time'

export default {
  name: 'Incident_0',

  props: {
    cadIncident: Object,
    geofence: Object,
    group: Object,
  },

  data() {
    return {
      rawOn: false,
    }
  },

  methods: {
    unitTypeStyles,

    whichOn(which) {
      const ons = ['rawOn']

      ons.forEach(on => {
        if (which != on) {
          this[on] = false
        } else {
          this[on] = !this[on]
        }
      })
    },
  },

  filters: {
    fmtTime,
  },
}
</script>

<style scoped src="@/assets/css/incident.css" />
