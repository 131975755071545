<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="container">
          <span class="title">Need assistance?</span>
          <span class="line"
            ><a href="mailto:help@simplesense.io">help@simplesense.io</a></span
          >
          <span class="line"><a href="tel:+18142402423">814-240-2423</a></span>
          <footer class="modal-footer">
            <button
              class="btn-close"
              type="button"
              @click="$emit('close')"
              aria-label="Close modal"
            >
              Close
            </button>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped src="@/assets/css/incident.css" />
<style scoped>
.line {
  display: block;
  text-align: center;
}
.title {
  display: block;
  text-align: center;
  padding: 25px;
}
.container a {
  color: var(--simplesense);
}
</style>
